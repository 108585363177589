import React, { useEffect, useState, useRef } from 'react';
import actions from 'actions';
import config from 'config';
import './styles.scss';

const DescriptionForm = ({ spot, onChange }) => {
  const noButtonRef = useRef();
  const [city, setCity] = useState('');
  const [name, setName] = useState(`${spot.name}. Located in: ${spot.country}`);

  const getCity = async () => {
    const { lat, lng } = spot;

    const r = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=en&key=${config.googleMaps.apiKey}`
    );
    const json = await r.json();
    const pieces = json.results[0].address_components;
    const city = (
      pieces.find(x => x.types.includes('locality')) ||
      pieces.find(x => x.types.includes('political')) || { long_name: '' }
    ).long_name;

    if (city) {
      setName(`${spot.name}. Located in: ${city}, ${spot.country}`);
      setCity(city);
    }
  };

  useEffect(() => {
    getCity();

    const _onKeyPress = e => {
      const actionButton = document.querySelector(`.DescriptionForm [data-action-key="${e.key}"]`);
      // event.metaKey is CMD (CMD + 3, for example)
      if (actionButton && !e.metaKey && actionButton.click) {
        e.preventDefault();
        actionButton.click();
      }
    };

    document.addEventListener('keydown', _onKeyPress, false);

    return () => {
      document.removeEventListener('keydown', _onKeyPress, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     if (city) {
  //       const WORDS = [
  //         ' falls',
  //         ' waterfall',
  //         ' waterfalls',
  //         ' bridge',
  //         ' cathedral',
  //         'catedral ',
  //         'cattedrale ',
  //         'palazzina',
  //         'chiesa ',
  //         ' church',
  //         'church ',
  //         'iglesia ',
  //         ' chapel',
  //         'chapel ',
  //         'monastero ',
  //         'burg ',
  //         ' fortress',
  //         'plaza ',
  //         ' lake',
  //         'lake ',
  //         'lago ',
  //         'laguna ',
  //         'lac ',
  //         ' beach',
  //         'praia ',
  //         'playa ',
  //         ' peak',
  //         ' museum',
  //         'park',
  //         'tempio',
  //         'viaduto',
  //         ' lighthouse',
  //         'bunker',
  //         'giardini',
  //         'giardino',
  //         'castle',
  //         'maison',
  //         'bosque',
  //         'muelle',
  //         'castillo ',
  //         'château ',
  //         ' island',
  //         ' palace',
  //         'harbour',
  //         ' river',
  //         'rio ',
  //         'river ',
  //         'calles de ',
  //         'río ',
  //         ' theatre',
  //         'opera',
  //         ' memorial',
  //         'memorial ',
  //         ' mosque',
  //         'ponte ',
  //         'museo ',
  //         'plage ',
  //         'puente ',
  //         'mount ',
  //         'parque',
  //         'cathédrale',
  //         'parroquia ',
  //         ' temple',
  //         'temple of ',
  //         'windmill',
  //         'reservoir',
  //         'jardin',
  //         'city hall',
  //         ' college',
  //         ' rock',
  //         ' station',
  //         ' garden',
  //         'forte ',
  //         'fort ',
  //         ' fort',
  //         ' see',
  //         'recreation',
  //         'castel ',
  //         'castelo ',
  //         ' stadium',
  //         ' square',
  //         'port ',
  //         'faro ',
  //         ' dam',
  //         ' tower',
  //         'piazza ',
  //         ' dunes',
  //         ' dune ',
  //         'dune ',
  //         'aquarium',
  //         'salto ',
  //         'cachoeira',
  //         'wasserfälle',
  //         'museum of ',
  //         'glacier',
  //         'glaciar',
  //         'galeria',
  //         'galerie',
  //         ' corner',
  //         'unesco',
  //         'estanque',
  //         ' château',
  //         'mirador ',
  //         'torrent',
  //         ' viewpoint',
  //         ' view',
  //         'battery',
  //         ' pike',
  //         'praza ',
  //         'placeta ',
  //         'shipwreck',
  //         'monte ',
  //         'montaña ',
  //         'buddha',
  //         'brücke',
  //         'forts ',
  //         'fontaine',
  //         'terrazza',
  //         'palacio ',
  //         'cathedral of ',
  //         'palace ',
  //         'palazzo ',
  //         'institute',
  //         'library',
  //         'duomo di',
  //         ' cemetery',
  //         ' mountain',
  //         'pont ',
  //         ' bay',
  //         'pointe ',
  //         'cappella ',
  //         'tower of ',
  //         'castello ',
  //         ' cove',
  //         'lago ',
  //         ' hill',
  //         ' hills',
  //         'platja ',
  //         ' hall',
  //         ' abbey',
  //         'abbey ',
  //         'cascada ',
  //         'chateau',
  //         'acueducto',
  //         'spiaggia ',
  //         'schloss ',
  //         ' dam',
  //         ' harbor',
  //         'cliff',
  //         'igreja ',
  //         ' creek',
  //         ' gallery',
  //         ' reservation',
  //         'isole ',
  //         'isola ',
  //         'arco ',
  //         ' arch',
  //         'national',
  //         'pasaje',
  //         ' track',
  //         ' trail',
  //         'reserva natural',
  //         'mausoleum',
  //         'plaça ',
  //         'cala ',
  //         ' geyser',
  //         'cerro ',
  //         ' monument',
  //         'monumento ',
  //         'isla ',
  //         ' viaduct',
  //         ' mural',
  //         'lagunas',
  //         'congres',
  //         ' house',
  //         'markplatz',
  //         ' tomb',
  //         ' port',
  //         'basilica',
  //         'university',
  //         ' tunnel',
  //         'lighthouse ',
  //         'gallery ',
  //         'praça ',
  //         ' point',
  //         ' arena',
  //         'phare ',
  //         ' pier',
  //         'mercato ',
  //         'embalse ',
  //         'torre ',
  //         ' torre',
  //         'cape ',
  //         'market',
  //         ' observatory',
  //         ' arts',
  //         ' of art',
  //         'place ',
  //         ' place',
  //         'aqueduc',
  //         'ermita',
  //         'convento',
  //         'sacré',
  //         'sacre',
  //         'mansion',
  //         'monastery',
  //         'jardim ',
  //         'palau ',
  //         'chiostri ',
  //         'statue of ',
  //         ' statue',
  //         'ayuntamiento ',
  //         'wind ',
  //         'muralla',
  //         'biblioteca ',
  //         'arc ',
  //         ' pass',
  //         ' cave',
  //         'islote ',
  //         'mt.',
  //         ' cavern',
  //         'porta ',
  //         'st.',
  //         'saint',
  //         'basilique',
  //         'universidad',
  //         ' wasserfall',
  //         'eglise ',
  //         'lagoon',
  //         'valley',
  //         'museum',
  //         ' zoo',
  //         'capitol',
  //         'cape ',
  //         'marina',
  //         'marine',
  //         'rock formations',
  //         'playas ',
  //         'subway',
  //         'estatua ',
  //         'abbazia',
  //         ' lookout',
  //         'central',
  //         ' sculpture',
  //         'sculpture ',
  //         'centro ',
  //         'refugio ',
  //         'old ',
  //         'palais',
  //         'fountain',
  //         'punta ',
  //         ' hotel',
  //         'hotel ',
  //         'monument ',
  //         ' center',
  //         ' centre',
  //         'bodega',
  //         "san't",
  //         'patio ',
  //         ' forest',
  //         'markt',
  //         'gallerie',
  //         'cupola',
  //         'foundation',
  //         ' ruin',
  //         ' ruins',
  //         'ruins ',
  //         ' reserve',
  //         'cascade ',
  //         ' spring',
  //         ' san ',
  //         'wreck ',
  //         ' wreck',
  //         'puerta ',
  //         ' platz',
  //         'fontana',
  //         'cueva ',
  //         'wasserfall',
  //         ' fall',
  //         'amphitheater',
  //         'amphitheatre',
  //         'theater',
  //         'peak ',
  //         'refuge',
  //         'fuente ',
  //         'conservatory',
  //         'point ',
  //         'salinas ',
  //         'cabo ',
  //         'chapelle ',
  //         ' maria ',
  //         'parrocchia',
  //         'bahia',
  //         'bahía',
  //         'porto ',
  //         'puerto ',
  //         'cenote',
  //         ' brug',
  //         'canyon',
  //         'illa',
  //         'lagos ',
  //         ' gate',
  //         'gate ',
  //         'costa ',
  //         'túnel',
  //         ' sign',
  //         ' forum',
  //         'pyramid',
  //         'morro ',
  //         'rathaus',
  //         'cascata ',
  //         ' molen',
  //         'molen ',
  //         'teatro',
  //         'molino',
  //         'comune',
  //         'wheel',
  //         'galleria',
  //         'bastion',
  //         ' dom',
  //         'desert',
  //         'mausoleo',
  //         'sierra ',
  //         'vineyard',
  //         'farol ',
  //         'cuevas ',
  //         'parliament',
  //         'cap de ',
  //         'far de ',
  //         'far des ',
  //         'mont ',
  //         'spiagge',
  //         'camino a ',
  //         'capela',
  //         'virgen',
  //         'musée ',
  //         'umbrella',
  //         'dent ',
  //         'gare du ',
  //         'prahia',
  //         'castell',
  //       ];

  //       const isKnownName = WORDS.some(word => spot.name.toLowerCase().includes(word));

  //       const isKnownCountry = [
  //         'Germany',
  //         'Netherlands',
  //         'Egypt',
  //         'Poland',
  //         'Slovakia',
  //         'Russia',
  //         'Sweden',
  //         'Austria',
  //         'Norway',
  //         'Romania',
  //         'Vietnam',
  //         'Japan',
  //         'Kazakhstan',
  //         'Switzerland',
  //       ].includes(spot.country);

  //       if (isKnownName) {
  //         onGetDescription();
  //       } else if (
  //         spot.from &&
  //         [
  //           'entrance',
  //           'below',
  //           'stairs',
  //           'drone',
  //           'viewpoint',
  //           'inside',
  //           'trail',
  //           'front',
  //         ].includes(spot.from.toLowerCase().trim())
  //       ) {
  //         onGetDescription();
  //       } else if (spot.from) {
  //         const isKnownFrom = WORDS.some(word => spot.from.toLowerCase().includes(word));

  //         if (isKnownFrom || spot.from.length > 20) {
  //           onGetDescription(`${spot.name} and ${spot.from}. Located in: ${city}, ${spot.country}`);
  //         } else if (isKnownCountry) {
  //           onGetDescription();
  //         }
  //       } else if (isKnownCountry) {
  //         onGetDescription();
  //       } else if (!spot.from) {
  //         onGetDescription();
  //       }
  //     }
  //   })();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [city]);

  const onCancel = () => {
    if (!window.confirm('You sure?')) {
      return;
    }

    onChange({ spotId: spot.id, description: { en: '' } });
    actions.spots.edit(spot, { description: { en: '' } });
  };

  const onGetDescription = async forcedName => {
    try {
      const role = 'I want to you to act as a travel guide.';
      const question = `I will give you the name of a place, and you will give me a brief information about it, without any introduction, which should be useful for tourists/travelers. Keep your answer within 600 and 800 characters long. The place is: ${
        forcedName ? forcedName : name
      }.`;

      onChange({ spotId: spot.id, description: { en: '?' } });

      const answer = await actions.openai.getTextV4(role, question);

      await actions.spots.edit(spot, { description: { en: answer } });

      await actions.spots.translateDescriptionById(spot.id);
    } catch (e) {
      console.log('$$ error', e, spot);
      window.alert('Error getting/saving description: ' + e.message);
    }
  };

  return (
    <div className="DescriptionForm">
      <div>Spot Id: {spot.id}</div>

      <div>
        <button
          autofocus
          data-action-key="1"
          className="approve"
          onClick={() => onGetDescription()}
        >
          GET DESCRIPTION (1)
        </button>

        <button ref={noButtonRef} data-action-key="2" className="reject" onClick={() => onCancel()}>
          DO NOT GET DESCRIPTION (2)
        </button>
      </div>

      {spot.from && (
        <div
          className="DescriptionForm__from"
          onClick={() =>
            setName(`${spot.name} and ${spot.from}. Located in: ${city}, ${spot.country}`)
          }
        >
          {spot.from}
        </div>
      )}
      <div>
        <input type="text" value={name} onChange={e => setName(e.target.value)} />
      </div>

      {spot.photosHydrated.map(x => (
        <img key={x.id} src={x.urls.regular} alt={spot.name} />
      ))}

      {spot.userPhotosHydrated.map(x => (
        <img key={x.id} src={x.url} alt={spot.name} />
      ))}
    </div>
  );
};

export default DescriptionForm;
